import joieMuskokaImage from './images/joie-muskoka_mobile-1.jpg';

const data = [
  {
    name: 'Simpli',
    description: 'Team-based project tracker app',
    languages: 'JavaScript, PostgreSQL, HTML/CSS',
    frameworks: 'Express, React, jsonwebtoken, bcrypt',
    website: 'https://github.com/john-ngai/simpli/',
    image: 'https://github.com/john-ngai/simpli/blob/john/scheduler/docs/02_projects&deliverables.png?raw=true',
    featured: true,
  },
  {
    name: 'Resource Haven',
    description: 'Pinterest-inspired resource bulletin',
    languages: 'JavaScript, PostgreSQL, HTML/CSS',
    frameworks: 'Express, jQuery, cookie-session, bcrypt',
    website: 'https://github.com/john-ngai/resource-haven/',
    image: 'https://github.com/NealePT/lhl-midterm/blob/master/docs/resource-page.png?raw=true',
    featured: true,
  },
  {
    name: 'Interview Scheduler',
    description: 'App for booking & canceling interviews',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'Express, React, Axios, classnames',
    website: 'https://github.com/john-ngai/scheduler',
    image: 'https://github.com/john-ngai/scheduler/blob/main/docs/Product-Screenshot_02.png?raw=true',
    featured: true,
  },
  {
    name: 'JoieMuskoka.com',
    description: 'Client cottage rental website',
    languages: 'Javscript, HTML/CSS',
    frameworks: 'React, SASS, @fortawesome',
    website: 'https://joiemuskoka.com/',
    image: joieMuskokaImage,
    featured: true,
  },
  {
    name: 'Quizzical',
    description: 'Trivia questions game',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'React',
    website: 'https://github.com/john-ngai/react_quizzical-app',
    image: 'https://github.com/john-ngai/react_quizzical-app/blob/main/docs/03_answers.png?raw=true',
    featured: true,
  },
  {
    name: 'Tenzi',
    description: 'Tenzi dice game',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'React',
    website: 'https://github.com/john-ngai/react_tenzies/',
    image: 'https://github.com/john-ngai/react_tenzies/blob/main/docs/01_New-Game.png?raw=true',
    featured: true,
  },
  {
    name: 'Template: Responsive Layout',
    description: 'Responsive desktop/mobile layout',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'React',
    website: 'https://github.com/john-ngai/template_responsive-layout-01',
    image: '',
    featured: false,
  },
  {
    name: 'Travel Journal',
    description: 'Airbnb-inspired travel journal',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'React',
    website: 'https://github.com/john-ngai/react_travel-journal/',
    image: '',
    featured: false,
  },
  {
    name: 'Digital Business Card',
    description: 'Built using React components',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'React',
    website: 'https://github.com/john-ngai/react_business-card/',
    image: '',
    featured: false,
  },
  {
    name: 'JohnNgai.com',
    description: 'Personal website',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'React, sass, @fortawesome',
    website: 'https://github.com/john-ngai/johnngai.com/',
    image: '',
    featured: false,
  },
  {
    name: 'Jungle',
    description: 'Mini eCommerce app',
    languages: 'Rails 4.2, PostgreSQL, HTML/CSS',
    frameworks: 'sass-rails, jquery-rails, bcrypt, stripe',
    website: 'https://github.com/john-ngai/jungle-rails/',
    image: '',
    featured: false,
  },
  {
    name: 'Tweeter',
    description: 'Simple single-page Twitter clone',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'Express, jQuery, MD5',
    website: 'https://github.com/john-ngai/tweeter/',
    image: '',
    featured: false,
  },
  {
    name: 'TinyApp',
    description: 'URL shortener (à la bit.ly)',
    languages: 'JavaScript, HTML/CSS',
    frameworks: 'Express, ejs, bcrypt, cookie-session',
    website: 'https://github.com/john-ngai/tinyapp',
    image: '',
    featured: false,
  },
  {
    name: 'Lotide',
    description: 'Mini Lodash library clone',
    languages: 'JavaScript',
    frameworks: '',
    website: 'https://github.com/john-ngai/lotide/',
    image: '',
    featured: false,
  },
];

export default data;